<template>
    <div class="home">

        <transition name="fade" duration="300">
            <PreloaderComponent v-if="uploadPreloader"/>
        </transition>


        <div class="center">
            <img src="../assets/img/logo-new.png" alt="">
        </div>
        <div class="container">


            <h1 class="upr">Панель управления</h1>

            <button @click="logOut" class="button" style="margin-top: 10px">Выход из панели управления</button>

            <div class="menu">
                <div class="menu-punkt" :class="{'active':type==='track'}" @click="type='track'">Треки</div>
                <div class="menu-punkt" :class="{'active':type==='ringtone'}" @click="type='ringtone'">Рингтоны</div>
                <div class="menu-punkt" :class="{'active':type==='audio'}" @click="type='audio'">Аудиозаписи</div>
                <div class="menu-punkt" :class="{'active':type==='phonograme'}" @click="type='phonograme'">Фонограммы
                </div>
                <div class="menu-punkt" :class="{'active':type==='slideshow'}" @click="type='slideshow'">Слайд-шоу</div>
                <div class="menu-punkt" :class="{'active':type==='performance'}" @click="type='performance'">
                    Выступления
                </div>
                <div class="menu-punkt" :class="{'active':type==='services'}" @click="type='services'">Услуги</div>
            </div>


            <div v-if="type==='services'">
                <form action="#" method="get">
                    <div><input v-model="text" class="form" type="text" name="" placeholder="Добавить услугу">
                    </div>
                    <div>
                        <button class="button" v-bind:disabled="buttonDisabled" @click.prevent="addService">
                            Загрузить
                        </button>
                    </div>
                </form>
                <h2 class="title">Список услуг</h2>
                <ul>
                    <li style="margin-bottom: 10px" v-for="item in data.services" :key="item.type+item.id">
                        {{item.text}} <a href="#"
                                                     @click="deleteService(item.id)">Удалить</a>
                    </li>
                </ul>
            </div>
            <template v-else>
                <div v-if="type==='slideshow' || type==='performance'">
                    <div class="video">
                        <form action="#" method="get">
                            <div><input v-model="url" class="form" type="text" name="" placeholder="Добавить ссылку">
                            </div>
                            <div>
                                <button class="button" v-bind:disabled="buttonDisabled" @click.prevent="addVideo">
                                    Загрузить
                                </button>
                            </div>
                        </form>
                    </div>
                </div>

                <div v-else class="download">
                    <form action="#" method="get">
                        <div><input class="form" v-model="name" type="text" name="" placeholder="Ввести название"></div>
                        <div><input ref="audio_input" class="form" type="file" name="Выбрать файл" id=""></div>
                        <div>
                            <button class="button" v-bind:disabled="buttonDisabled" @click.prevent="addAudio">Загрузить
                            </button>
                        </div>
                    </form>

                </div>

                <div v-if="data">
                    <h2 class="title">Список данных</h2>
                    <ul>
                        <li v-for="item in data[type]" :key="item.type+item.id">
                            <input style="margin-right: 10px" v-bind:checked="!!item.download" type="checkbox" @change="(e)=>{changeDownloadStatus(e, item.id)}">
                            {{item.name || item.url}} <a href="#"
                                                         @click="item.name ? deleteAudio(item.id) : deleteVideo(item.id)">Удалить</a>
                        </li>
                    </ul>
                </div>
            </template>


        </div>
    </div>
</template>

<script>
    import axios from 'axios'
    import PreloaderComponent from "../components/PreloaderComponent";

    export default {
        name: 'Home',
        components: {PreloaderComponent},
        data() {
            return {
                type: 'track',
                data: null,
                url: '',
                name: '',
                file: null,
                text:'',
                buttonDisabled: false,
                uploadPreloader: false
            }
        },
        methods: {
            logOut() {
                location.href = 'https://gs-al.by/'
            },
            changeDownloadStatus(e, id){
                this.uploadPreloader = true
                const data = new FormData()
                data.append('download', e.target.checked ? '1' : '')
                axios.post('https://gs-al.by/api/audio/edit/'+id, data, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                    .then(response => {
                        console.log(response)
                        this.uploadPreloader = false
                    })
                    .catch(error => {
                        console.log(error.response)
                        this.uploadPreloader = false
                    })
            },
            addAudio() {
                if (this.$refs.audio_input.files && this.$refs.audio_input.files[0]) {
                    const data = new FormData()
                    data.append('audio', this.$refs.audio_input.files[0])
                    data.append('name', this.name)
                    data.append('type', this.type)
                    this.uploadPreloader = true
                    axios.post('https://gs-al.by/api/audio', data, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                        .then(response => {
                            console.log(response)
                            this.refreshData()
                            this.uploadPreloader = false
                        })
                        .catch(error => {
                            console.log(error.response)
                            this.uploadPreloader = false
                        })
                }
            },
            addVideo() {
                const data = new FormData()
                data.append('url', this.url)
                data.append('type', this.type)
                this.uploadPreloader = true
                axios.post('https://gs-al.by/api/video', data, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                )
                    .then(response => {
                        console.log(response)
                        this.refreshData()
                        this.uploadPreloader = false
                    })
                    .catch(error => {
                        console.log(error.response)
                        this.uploadPreloader = false
                    })
            },
            deleteAudio(id) {
                this.uploadPreloader = true
                axios.delete('https://gs-al.by/api/audio/delete/' + id)
                    .then(response => {
                        console.log(response)
                        this.refreshData()
                        this.uploadPreloader = false
                    })
                    .catch(error => {
                        console.log(error.response)
                        this.uploadPreloader = false
                    })
            },
            deleteVideo(id) {
                this.uploadPreloader = true
                axios.delete('https://gs-al.by/api/video/delete/' + id)
                    .then(response => {
                        console.log(response)
                        this.refreshData()
                        this.uploadPreloader = false
                    })
                    .catch(error => {
                        console.log(error.response)
                        this.uploadPreloader = false
                    })
            },
            addService() {
                const data = new FormData()
                data.append('text', this.text)
                this.uploadPreloader = true
                axios.post('https://gs-al.by/api/service', data, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                )
                    .then(response => {
                        console.log(response)
                        this.text=''
                        this.refreshData()
                        this.uploadPreloader = false
                    })
                    .catch(error => {
                        console.log(error.response)
                        this.uploadPreloader = false
                    })
            },
            deleteService(id) {
                this.uploadPreloader = true
                axios.delete('https://gs-al.by/api/service/delete/' + id)
                    .then(response => {
                        console.log(response)
                        this.refreshData()
                        this.uploadPreloader = false
                    })
                    .catch(error => {
                        console.log(error.response)
                        this.uploadPreloader = false
                    })
            },
            async refreshData() {
                this.data = await axios.get('https://gs-al.by/api/get-data').then(r => r.data.result)
            }
        },
        created() {
            this.refreshData()
        }
    }
</script>


<style lang="scss">
    * {
        padding: 0;
        margin: 0;
        border: 0;
    }

    *,
    *::before,
    *:after {
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
    }

    :focus,
    :active {
        outline: none;
    }

    a:focus,
    a:active {
        outline: none;
    }

    nav,
    footer,
    header,
    aside {
        display: block;
    }

    html,
    body {
        font-family: Verdana, Geneva, Tahoma, sans-serif;
        height: 100%;
        width: 100%;
        font-size: 100%;
        line-height: 1;
        font-size: 14px;
        -ms-text-size-adjust: 100%;
        -moz-text-size-adjust: 100%;
        -webkit-text-size-adjust: 100%;
    }

    input,
    button,
    textarea {
        font-family: inherit;
    }

    input::-ms-clear {
        display: none;
    }

    button {
        cursor: pointer;
    }

    button::-moz-focus-inner {
        padding: 0;
        border: 0;
    }

    a,
    a:visited {
        text-decoration: none;
    }

    a:hover {
        text-decoration: none;
    }

    ul li {
        list-style: none;
    }

    img {
        vertical-align: top;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-size: inherit;
        font-weight: 400;
    }

    body {
        background-color: #f4f5f7;
        color: #1d1d1f;
    }

    .title {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 10px;
    }

    .container {
        padding: 0 20px;
        max-width: 800px;
        margin: 0 auto;
    }

    .upr {
        color: green;
        font-size: 40px;
        margin-top: 25px;
    }

    .menu {
        margin: 30px 0px;
    }

    .menu-punkt {
        display: inline-block;
        margin: 20px 30px 0 0;
        padding: 10px;
        border-radius: 50px;
        transition: all ease .3s;
        font-size: 16px;
        font-weight: 600;
    }

    .menu-punkt.active,
    .menu-punkt:hover {
        color: #5f9d89;
        background-color: #ffffff;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
        cursor: pointer;
    }

    .download {
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
        margin: 40px 0px;
        padding: 10px;
        border-radius: 10px;
        background-color: #f9fafc;
    }

    .download:hover {
        color: #5f9d89;
        background-color: #ffffff;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
        cursor: pointer;
    }

    .form {
        border: 1px solid rgb(170, 170, 170);
        margin: 10px;
        padding: 10px;
        border-radius: 10px;
        background-color: rgb(249, 250, 252);
    }

    .video {
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
        margin: 40px 0px;
        padding: 10px;
        border-radius: 10px;
        background-color: #f9fafc;
    }

    .button {
        display: inline-block;
        height: 2em;
        line-height: 2em;
        vertical-align: middle;
        text-align: center;
        text-decoration: none;
        user-select: none;
        color: rgb(0, 0, 0);
        outline: none;
        border: 1px solid rgba(0, 0, 0, .4);
        border-top-color: rgba(0, 0, 0, .3);
        border-radius: 2px;
        background: linear-gradient(rgb(255, 255, 255), rgb(240, 240, 240));
        box-shadow: 0 0 3px rgba(0, 0, 0, 0) inset, 0 1px 1px 1px rgba(255, 255, 255, .2), 0 -1px 1px 1px rgba(0, 0, 0, 0);
        transition: .2s ease-in-out;
        padding: 0 15px;
    }

    .button:hover:not(:active) {
        box-shadow: 0 0 3px rgba(0, 0, 0, 0) inset, 0 1px 1px 1px rgba(0, 255, 255, .5), 0 -1px 1px 1px rgba(0, 255, 255, .5);
    }

    .button:active {
        background: linear-gradient(rgb(250, 250, 250), rgb(235, 235, 235));
        box-shadow: 0 0 3px rgba(0, 0, 0, .5) inset, 0 1px 1px 1px rgba(255, 255, 255, .4), 0 -1px 1px 1px rgba(0, 0, 0, .1);
    }

    .center {
        text-align: center;
        padding: 20px 0 20px 0;
        background-color: #22b14c;
    }

</style>
